import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import PageWrapper from "../components/PageWrapper"
import Seo from "../components/Seo"
import Container from "../components/Container"
import { PageTitle } from "../components/PageTitles"
import Button from "../components/Button"


const AboutPage = () => {
    const data = useStaticQuery(graphql`
    query AboutPageQuery {
        wpSnippet(id: {eq: "cG9zdDoxMTQ4"}) {
          title
          content
        }
      }
  `)

  return (
    <Layout>
      <Seo title="About" description={`About Lighter Than Air and Lighter Than Air Records, an artist-oriented management company and record label based in North America and beyond.`} />
        <PageWrapper>

        <Container size={`medium`}>
            <PageTitle className={`md:hidden block`}>About <br/>Lighter <br/>Than Air</PageTitle>
            <h1 className={`md:block hidden text-9xl uppercase italic !-tracking-[0.5rem]`}>About <br/>Lighter <br/>Than Air</h1>
            <div className={`mt-8 leading-[175%] space-y-7 md:text-lg lg:text-3xl lg:font-normal lg:font-mono lg:leading-normal lg:uppercase lg:space-y-12 lg:mt-14`} dangerouslySetInnerHTML={{__html: data.wpSnippet.content}} />
            <div className={`mt-8`}>
                <div className={`mt-6 flex flex-row flex-wrap space-x-3 md:space-x-5 lg:mt-10`}>
                    <Button type={`internal`} to={`/contact`}>Contact Us</Button>
                    <Button type={`internal`} to={`/`} variation={`secondary`}>Home</Button>
                </div>
            </div>
            
         </Container>
  
        </PageWrapper>
    </Layout>
  )
}

export default AboutPage
